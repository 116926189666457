







































































































































import { DaoDetailSettingViewModel } from '@/modules/community-organize/viewmodels/dao-detail-setting-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'delete-dao-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/delete-dao-dialog.vue'),
  },
})
export default class extends Vue {
  @Inject({}) setting!: DaoDetailSettingViewModel
  @Prop({ default: false, type: Boolean }) selectType!: boolean

  valueSelected: any

  get lastPath() {
    const paths = this.$route.fullPath.split('/')
    return paths[paths.length - 1]
  }

  @Watch('$route.fullPath', { immediate: true }) onRouteChange() {
    const item = this.setting.items.find((item) => item.path === this.lastPath)
    this.valueSelected = item
  }

  toPage(path: string): void {
    if (path === 'delete') {
      this.setting.setOpenDeleteDaoDialog(true)
    } else if (this.lastPath !== path) {
      const index = this.$route.path.lastIndexOf('/')
      this.$router.push(this.$route.path.substring(0, index + 1) + path)
    }
  }
  redirectToDaoDetail() {
    if (this.setting.isRctDao) this.$router.go(-1)
    else if (this.setting.daoId) {
      this.$router.replace(`/community-organize/dao-detail/${this.setting.daoId}`)
    }
  }
}
